import React from 'react';
import LandingPage from './components/LandingPage';
import './App.css';
import { useEffect } from "react";

function App() {
    useEffect(() => {
    getAPICall();
  }, []);

  async function getAPICall() {
    const res = await fetch("https://api.dhan.co/v2/charts/historical", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJkaGFuIiwicGFydG5lcklkIjoiIiwiZXhwIjoxNzMyNDY1MzM4LCJ0b2tlbkNvbnN1bWVyVHlwZSI6IlNFTEYiLCJ3ZWJob29rVXJsIjoiIiwiZGhhbkNsaWVudElkIjoiMTAwMDY3NzM3OSJ9.cAcU2cWZRMN_fDUi-6tZS2ly2nPwLxnpkAMZUrqfCckzzsLhNx_teKRur56hl3kdmMS1VtplMT5V1PUY4e1Mpw"
      },
      body: JSON.stringify({
        securityId: "21679",
        exchangeSegment: "NSE_EQ",
        instrument: "EQUITY",
        expiryCode: 0,
        fromDate: "2024-10-22",
        toDate: "2024-11-24",
      }),
    });
    const data = await res.json();
    console.log('api data',data);
  }
  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;
